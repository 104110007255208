const isBrowser = typeof window !== `undefined`;

const getUser = () => (window.localStorage.gatsbyUser ? JSON.parse(window.localStorage.gatsbyUser) : {});

const setUser = (user) => (window.localStorage.gatsbyUser = JSON.stringify(user));

export const handleLogin = async ({ kioskDatabaseName, password, username }) => {
    if (!isBrowser) return false;

    const user = {
        clientDbName: `${kioskDatabaseName}_clients`,
        mainDbName: kioskDatabaseName,
        password,
        productDbName: `${kioskDatabaseName}_products`,
        username,
    };

    setUser(user);

    return user;
};

export const isLoggedIn = () => {
    if (!isBrowser) return false;

    const user = getUser();

    return !!user.username;
};

export const getCurrentUser = () => isBrowser && getUser();

export const logout = (callback) => {
    if (!isBrowser) return;

    console.info(`Ensuring the \`gatsbyUser\` property exists.`);
    setUser({});
    callback();
};
