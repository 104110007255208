export const PAGE_HOME = `/`;

export const PAGE_SEARCH_BASE = '/app/recherche/';
export const PAGE_SEARCH_RESULTS = '/app/recherche/:searchTerm';

export const PAGE_CATEGORIES = '/app/categories';
export const BROWSING_BASE_PATH = '/app/categorie/';
export const PAGE_SUBCATEGORIES = '/app/categorie/:categoryId';
export const PRODUCT_LIST = '/app/categorie/:categoryId/:subcategoryId/';
export const PRODUCT_ITEM = '/app/categorie/:categoryId/:subcategoryId/:productId';
export const PAGE_DIAGNOSTIC = '/app/diagnostic';
export const PAGE_DIAGNOSTIC_RESULTS = '/app/diagnostic/results';
export const PAGE_DIAGNOSTIC_RESULT_DETAILS = '/app/diagnostic/results/:categoryId';

export const PAGE_CART = '/app/ma-liste';

export const PAGE_ADMIN_LOGIN = '/app/admin/login';
export const PAGE_ADMIN_SETTINGS = '/app/admin/settings';
export const PAGE_ADMIN_CLIENTS = '/app/admin/clients';
export const PAGE_ADMIN_CLIENTS_DETAILS = PAGE_ADMIN_CLIENTS + '/:clientListId';
export const PAGE_ADMIN_SYNCHRO = '/app/admin/synchro';
